import Header from "../../components/shared/Header";
import "../../styles/shared/Blogs.scss";
import MainBlog from "../../images/image 1.png";
import Blog1 from "../../images/image 30 (2).png";
import Blog2 from "../../images/image 2 (2).png";
import Blog3 from "../../images/image 5.png";
import Blog4 from "../../images/image 6.png";
import Blog5 from "../../images/image 14.png";
import Blog6 from "../../images/image 17.png";
import Blog7 from "../../images/image 11.png";
import Blog8 from "../../images/image 20.png";
import Blog9 from "../../images/image 22.png";
import Blog10 from "../../images/image 24.png";
import Blog11 from "../../images/image 25.png";
import Blog12 from "../../images/image 26.png";
import Footer from "../../components/shared/Footer";
import { useNavigate } from "react-router-dom";
import { getBlogPosts } from "../../apis/AdminDashboard/users";
import { useEffect, useState } from "react";
import moment from "moment";
export default function Blogs() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadBlogPosts = async () => {
    setLoading(true);
    const response = await getBlogPosts();
    console.log("response", response);
    setBlogPosts(response?.data?.message?.items);
    setLoading(false);
  };

  useEffect(() => {
    loadBlogPosts();
  }, []);

  const navigate = useNavigate();
  return (
    <div className="blog">
      <Header />
      <div className="hero">
        <div className="title">
          <span>CloseCarbon</span>: Blogs, Interviews and Stories
        </div>
        <div className="subtitle">
          Subscribe to stay updated on latest carbon reduction initiatives,
          sustainable solutions, and environmental innovations.
        </div>
      </div>
      <div className="blog__search">
        <div className="blog__search__inner">
          <select
            name=""
            id=""
            // onChange={(e) => setText(e.target.value)}
          >
            <option value="">All</option>
          </select>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                stroke="#B1B1B1"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <input
              type="text"
              placeholder="Search blog here..."
              // value={text}
              // onChange={(e) => setText(e.target.value)}
            />

            <button>Search</button>
          </div>
        </div>

        <div className="blog__search__sort">
          <div>Sort By:</div>
          <select name="" id="">
            <option value="">
            Latest
            </option>
          </select>
        </div>
      </div>

      <div className="blog__title">
        Blog
      </div>

      <div className="blogPosts">
        <div className="gridMain grid1">
          {blogPosts?.map((blog) => (
            <div
              className="pointer"
              onClick={() => {
                navigate(`/blog/${blog?.id}`);
                window.scroll(0, 0);
              }}
            >
              <img src={MainBlog} alt="" />
              <div>
                <div className="subtitle_group">
                  <div className="subtitle">
                    <span>CloseCarbon Chronicles</span> :{" "}
                    {moment(parseInt(blog?.createdAt)).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="second_title">Renewable energy beginners guide</div>
                <div className="second_subtitle">An introductory overview of renewable energy sources like solar, wind, and hydro power, and how they contribute to reducing carbon emissions...</div>
                <div className="time">10 min read</div>
                {/* <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div> */}
              </div>

              <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
            </div>
          ))}
          {blogPosts?.map((blog) => (
            <div
              className="pointer"
              onClick={() => {
                navigate(`/blog/${blog?.id}`);
                window.scroll(0, 0);
              }}
            >
              <img src={Blog6} alt="" />
              <div>
                <div className="subtitle_group">
                  <div className="subtitle">
                    <span>CloseCarbon Chronicles</span> :{" "}
                    {moment(parseInt(blog?.createdAt)).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="second_title">Renewable energy beginners guide</div>
                <div className="second_subtitle">An introductory overview of renewable energy sources like solar, wind, and hydro power</div>
                <div className="time">10 min read</div>
                {/* <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div> */}
              </div>
              <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
            </div>
          ))}
        </div>

        <div className="gridMain">
          {blogPosts?.map((blog) => (
            <div
              className="pointer"
              onClick={() => {
                navigate(`/blog/${blog?.id}`);
                window.scroll(0, 0);
              }}
            >
              <img src={Blog4} alt="" />
              <div>
                <div className="subtitle_group">
                  <div className="subtitle">
                    <span>CloseCarbon Chronicles</span> :{" "}
                    {moment(parseInt(blog?.createdAt)).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="second_title">What is carbon offsetting and how does it work?</div>
                <div className="second_subtitle">An introductory overview of renewable energy sources like solar, wind, and hydro power, and how they contribute to reducing carbon emissions...</div>
                <div className="time">10 min read</div>
                {/* <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div> */}
              </div>
              <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
            </div>
          ))}
          {blogPosts?.map((blog) => (
            <div
              className="pointer"
              onClick={() => {
                navigate(`/blog/${blog?.id}`);
                window.scroll(0, 0);
              }}
            >
              <img src={Blog1} alt="" />
              <div>
                <div className="subtitle_group">
                  <div className="subtitle">
                    <span>CloseCarbon Chronicles</span> :{" "}
                    {moment(parseInt(blog?.createdAt)).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="second_title">10 simple ways to reduce carbon footprint</div>
                <div className="second_subtitle">An introductory overview of renewable energy sources like solar, wind, and hydro power, and how they contribute to reducing carbon emissions...</div>
                <div className="time">10 min read</div>
                {/* <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div> */}
              </div>
              <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
            </div>
          ))}
          {blogPosts?.map((blog) => (
            <div
              className="pointer"
              onClick={() => {
                navigate(`/blog/${blog?.id}`);
                window.scroll(0, 0);
              }}
            >
              <img src={Blog2} alt="" />
              <div>
                <div className="subtitle_group">
                  <div className="subtitle">
                    <span>CloseCarbon Chronicles</span> :{" "}
                    {moment(parseInt(blog?.createdAt)).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="second_title">The role of technology in carbon reduction</div>
                <div className="second_subtitle">An introductory overview of renewable energy sources like solar, wind, and hydro power, and how they contribute to reducing carbon emissions...</div>
                <div className="time">10 min read</div>
                {/* <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div> */}
              </div>
              <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
            </div>
          ))}
          {blogPosts?.map((blog) => (
            <div
              className="pointer"
              onClick={() => {
                navigate(`/blog/${blog?.id}`);
                window.scroll(0, 0);
              }}
            >
              <img src={Blog5} alt="" />
              <div>
                <div className="subtitle_group">
                  <div className="subtitle">
                    <span>CloseCarbon Chronicles</span> :{" "}
                    {moment(parseInt(blog?.createdAt)).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="second_title">The connection between climate change & mental health</div>
                <div className="second_subtitle">An introductory overview of renewable energy sources like solar, wind, and hydro power, and how they contribute to reducing carbon emissions...</div>
                <div className="time">10 min read</div>
                {/* <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div> */}
              </div>
              <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
            </div>
          ))}
        </div>

        <div className="stories">
          <div className="title">Interviews</div>
          <div className="storiesGrid gridMain">
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPost?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog5} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(blogPost?.createdAt)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="second_title">Green Technology Leader Interview</div>
                    <div className="second_subtitle">Discuss the ways technology and innovation are helping to lower carbon emissions, from smart energy management to data analytics...</div>
                  </div>
                </div>
                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPosts[0]?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog6} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(1703984400)).format("DD MMM YYYY")}
                      </div>
                    </div>
                    <div className="second_title">The Role of Technology in Carbon Reduction</div>
                    <div className="second_subtitle">Discuss the ways technology and innovation are helping to lower carbon emissions, from smart energy management to data analytics...</div>
                  </div>
                </div>
                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPost?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog7} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(blogPost?.createdAt)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="second_title">Green Technology Leader Interview</div>
                    <div className="second_subtitle">Discuss the ways technology and innovation are helping to lower carbon emissions, from smart energy management to data analytics...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPosts[0]?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog9} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(1703984400)).format("DD MMM YYYY")}
                      </div>
                    </div>
                    <div className="second_title">Climate Scientist Discussion</div>
                    <div className="second_subtitle">Discuss the ways technology and innovation are helping to lower carbon emissions, from smart energy management to data analytics...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPost?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog9} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(blogPost?.createdAt)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="second_title">{blogPost?.title}</div>
                    <div className="second_subtitle">{blogPost?.intro}...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPosts[0]?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog9} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(1703984400)).format("DD MMM YYYY")}
                      </div>
                    </div>
                    <div className="second_title">{blogPost?.title}</div>
                    <div className="second_subtitle">{blogPost?.intro}...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {/* <div className="pointer" onClick={() => {
      navigate(`/blog/${blogPosts[0]?.id}`)
    window.scroll(0, 0)
    }}>
      <div>
        <img src={Blog10} alt="" />
        <div>
        <div className="subtitle_group">
          <div className="subtitle">
            <span>CloseCarbon Chronicles</span> : 02 November 2023
          </div>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
              fill="#006633"
            />
          </svg>
        </div>
        <div className="second_title">
          The Role of Technology in Carbon Reduction
        </div>
        <div className="second_subtitle">
          Discuss the ways technology and innovation are helping to
          lower carbon emissions, from smart energy management to data
          analytics...
        </div>
        <div className="button_group">
          <div className="button">Ecofriendly</div>
          <div className="button">GreenTech</div>
          <div className="button">RenewableEnergy</div>
        </div>
        </div>
      </div>
    </div>
    <div className="pointer" onClick={() => {
      navigate(`/blog/${blogPosts[0]?.id}`)
    window.scroll(0, 0)
    }}>
      <div>
        <img src={Blog12} alt="" />
        <div>
        <div className="subtitle_group">
          <div className="subtitle">
            <span>CloseCarbon Chronicles</span> : 02 November 2023
          </div>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
              fill="#006633"
            />
          </svg>
        </div>
        <div className="second_title">
          The Role of Technology in Carbon Reduction
        </div>
        <div className="second_subtitle">
          Discuss the ways technology and innovation are helping to
          lower carbon emissions, from smart energy management to data
          analytics...
        </div>
        <div className="button_group">
          <div className="button">Ecofriendly</div>
          <div className="button">GreenTech</div>
          <div className="button">RenewableEnergy</div>
        </div>
        </div>
      </div>
    </div> */}
          </div>
        </div>
        <div className="stories">
          <div className="title">Stories</div>
          <div className="storiesGrid gridMain">
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPost?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog9} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(blogPost?.createdAt)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="second_title">Business Partnerships</div>
                    <div className="second_subtitle">Discuss the ways technology and innovation are helping to lower carbon emissions, from smart energy management to data analytics...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPosts[0]?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog12} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(1703984400)).format("DD MMM YYYY")}
                      </div>
                    </div>
                    <div className="second_title">Community Impact</div>
                    <div className="second_subtitle">Discuss the ways technology and innovation are helping to lower carbon emissions, from smart energy management to data analytics...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPost?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog10} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(blogPost?.createdAt)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="second_title">Carbon Offset Projects</div>
                    <div className="second_subtitle">Discuss the ways technology and innovation are helping to lower carbon emissions, from smart energy management to data analytics...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPosts[0]?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog9} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(1703984400)).format("DD MMM YYYY")}
                      </div>
                    </div>
                    <div className="second_title">NGO Collaborations</div>
                    <div className="second_subtitle">Discuss the ways technology and innovation are helping to lower carbon emissions, from smart energy management to data analytics...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPost?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog9} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(blogPost?.createdAt)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="second_title">{blogPost?.title}</div>
                    <div className="second_subtitle">{blogPost?.intro}...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {blogPosts?.map((blogPost) => (
              <div
                className="pointer"
                onClick={() => {
                  navigate(`/blog/${blogPosts[0]?.id}`);
                  window.scroll(0, 0);
                }}
              >
                <div>
                  <img src={Blog9} alt="" />
                  <div>
                    <div className="subtitle_group">
                      <div className="subtitle">
                        <span>CloseCarbon Chronicles</span> :{" "}
                        {moment(parseInt(1703984400)).format("DD MMM YYYY")}
                      </div>
                    </div>
                    <div className="second_title">{blogPost?.title}</div>
                    <div className="second_subtitle">{blogPost?.intro}...</div>
                  </div>
                </div>

                <div className="read">
                Read Blog
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7079 8.20863C18.0992 7.81726 18.0992 7.18274 17.7079 6.79137L11.3302 0.413725C10.9389 0.0223613 10.3043 0.0223613 9.91298 0.413725C9.52161 0.80509 9.52161 1.43962 9.91298 1.83098L15.582 7.5L9.91298 13.169C9.52161 13.5604 9.52161 14.1949 9.91298 14.5863C10.3043 14.9776 10.9389 14.9776 11.3302 14.5863L17.7079 8.20863ZM0.964844 8.50215H16.9993V6.49785L0.964844 6.49785L0.964844 8.50215Z" fill="#0D924F"/>
</svg>

              </div>
              </div>
            ))}
            {/* <div className="pointer" onClick={() => {
      navigate(`/blog/${blogPosts[0]?.id}`)
    window.scroll(0, 0)
    }}>
      <div>
        <img src={Blog10} alt="" />
        <div>
        <div className="subtitle_group">
          <div className="subtitle">
            <span>CloseCarbon Chronicles</span> : 02 November 2023
          </div>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
              fill="#006633"
            />
          </svg>
        </div>
        <div className="second_title">
          The Role of Technology in Carbon Reduction
        </div>
        <div className="second_subtitle">
          Discuss the ways technology and innovation are helping to
          lower carbon emissions, from smart energy management to data
          analytics...
        </div>
        <div className="button_group">
          <div className="button">Ecofriendly</div>
          <div className="button">GreenTech</div>
          <div className="button">RenewableEnergy</div>
        </div>
        </div>
      </div>
    </div>
    <div className="pointer" onClick={() => {
      navigate(`/blog/${blogPosts[0]?.id}`)
    window.scroll(0, 0)
    }}>
      <div>
        <img src={Blog12} alt="" />
        <div>
        <div className="subtitle_group">
          <div className="subtitle">
            <span>CloseCarbon Chronicles</span> : 02 November 2023
          </div>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
              fill="#006633"
            />
          </svg>
        </div>
        <div className="second_title">
          The Role of Technology in Carbon Reduction
        </div>
        <div className="second_subtitle">
          Discuss the ways technology and innovation are helping to
          lower carbon emissions, from smart energy management to data
          analytics...
        </div>
        <div className="button_group">
          <div className="button">Ecofriendly</div>
          <div className="button">GreenTech</div>
          <div className="button">RenewableEnergy</div>
        </div>
        </div>
      </div>
    </div> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
